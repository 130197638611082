exports.components = {
  "component---src-pages-ccu-docs-js": () => import("./../../../src/pages/ccu-docs.js" /* webpackChunkName: "component---src-pages-ccu-docs-js" */),
  "component---src-pages-ccu-js": () => import("./../../../src/pages/ccu.js" /* webpackChunkName: "component---src-pages-ccu-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-developers-release-notes-js": () => import("./../../../src/pages/developers/release-notes.js" /* webpackChunkName: "component---src-pages-developers-release-notes-js" */),
  "component---src-pages-fiscalization-api-js": () => import("./../../../src/pages/fiscalization-api.js" /* webpackChunkName: "component---src-pages-fiscalization-api-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-single-article-js": () => import("./../../../src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */)
}

